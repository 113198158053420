/*
  Defines the iOn Suite DLS font-faces: Neo Sans Std 400, 500, 700
  https://www.figma.com/file/gtF4hB3eC4fvzKVJ2DNDtu/01_DLS_Foundation?node-id=1%3A2
 */
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';

/* Defines the Neo Sans Std font-face (400, 500, 700 weights) */
@font-face {
  font-family: 'Neo Sans Std';
  font-weight: 400;
  src: url('../fonts/Neo-Sans-Light.otf') format('otf'),
       url('../fonts/Neo-Sans-Light.woff2') format('woff2'),
       url('../fonts/Neo-Sans-Light.woff') format('woff'),
       url('../fonts/Neo-Sans-Light.ttf')  format('truetype'),;
}
@font-face {
  font-family: 'Neo Sans Std';
  font-weight: 500;
  src: url('../fonts/Neo-Sans-Regular.otf') format('otf')
       url('../fonts/Neo-Sans-Regular.woff2') format('woff'),
       url('../fonts/Neo-Sans-Regular.woff') format('woff'),
       url('../fonts/Neo-Sans-Regular.ttf')  format('truetype'),;
}
@font-face {
  font-family: 'Neo Sans Std';
  font-weight: 700;
  src: url('../fonts/Neo-Sans-Bold.ttf') format('ttf'),
       url('../fonts/Neo-Sans-Bold.woff2') format('woff2'),
       url('../fonts/Neo-Sans-Bold.woff') format('woff'),
       url('../fonts/Neo-Sans-Bold.ttf')  format('truetype');
}
